<template>
  <div class="headerbox">
  <div class="header">
    <div class="headerL">
      <a onclick="javascript:history.back(-1)" class="goback"><img src="images/goback.png" /></a>
    </div>
    <div class="headerC">
      <p>我的账户</p>
    </div>
    <div class="headerR"></div>
  </div>
</div>
<div class="clear"></div>
<div class="hbox"></div>
<div class="jfheader">
  <div class="guize">积分使用规则</div>
  <div class="jfnum">158<span>个</span></div>
  <div class="jfsub">小积分，有大用，多领一些屯起来！</div>
  <div class="jfgl">
    <ul>
      <li>
        <a href="javascript:;">全部</a>
      </li>
      <li>
        <a href="javascript:;">收入</a>
      </li>
      <li>
        <a href="javascript:;">支出</a>
      </li>
    </ul>
  </div>
</div>
<div class="clear"></div>
<div class="kbox"></div>
<div class="jfbox">
  <div class="jfbox1">
    <div class="jfbox1_L">
      <img src="images/gwc3.png" />
    </div>
    <div class="jfbox1_R">
      <div class="jfbox1_R1">
        <div class="v1">圣多明尼2015冬季冬装新款女装韩版宽...</div>
        <div class="v2">2014-12-12</div>
      </div>
      <div class="jfbox1_R2">
        <div class="v3">购物送积分</div>
        <div class="v4">+45</div>
      </div>
    </div>
    <div class="clear"></div>
  </div> 
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>